import React from "react";

//  components
import { Routes, Route } from "react-router-dom";

//  layouts
import Main from "./layouts/Main";
import Alternate from "./layouts/Alternate";

//  pages
import Home from "./pages/Home";
import Service from "./pages/Service";
import NotFound from "./pages/NotFound";
// import Policies from "./pages/Policies";
// import TermsOfUse from "./pages/TermsOfUse";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Main />}>
        <Route path="/" element={<Home />} />
      </Route>
      <Route path="/" element={<Alternate />}>
        <Route path="/service/*" element={<Service />} />
        {/* <Route path="/policies" element={<Policies />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} /> */}
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default App;
