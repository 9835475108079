//  styles
import { LightboxBackground, LightboxImg } from "./styles";

const Lightbox = ({ img, setImg }) => {
  return (
    <LightboxBackground onClick={() => setImg(null)}>
      {img && <LightboxImg src={img} />}
    </LightboxBackground>
  );
};

export default Lightbox;
