//  libraries
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//  components
import Slider from "react-slick";
import Feed from "./Feed";

//  styles
import { CarouselWrapper, SlideWrapper } from "./styles";

const InstagramSlider = ({ feeds, setLightboxImg }) => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    lazyLoad: true,
  };

  return (
    <CarouselWrapper>
      <Slider {...settings}>
        {feeds &&
          feeds.map((feed, i) => {
            return (
              <SlideWrapper key={i}>
                <Feed feed={feed} setLightboxImg={setLightboxImg} />
              </SlideWrapper>
            );
          })}
      </Slider>
    </CarouselWrapper>
  );
};

export default InstagramSlider;
