//  libraries
import styled from "styled-components";

export const Wrapper = styled.div`
  max-height: ${({ size }) => size || "10.5rem"};
  max-width: ${({ size }) => size || "10.5rem"};
`;

export const Img = styled.img`
  max-height: ${({ size }) => size || "10.5rem"};
  max-width: ${({ size }) => size || "10.5rem"};
  object-fit: cover;
`;
