//  hooks
import { useNavBar } from "../hooks/useNavBar";

//  components
import Hero from "../components/Hero/Hero";
import Services from "../components/Services/Services";
import Spaces from "../components/Spaces/Spaces";
import Community from "../components/Community/Community";
import AboutUs from "../components/AboutUs/AboutUs";
import Contact from "../components/Contact/Contact";

const Home = () => {
  const { hero, services, spaces, community, aboutUs, contact } = useNavBar();

  return (
    <>
      <Hero ref={hero.ref} />
      <Services ref={services.ref} />
      <Spaces ref={spaces.ref} />
      <Community ref={community.ref} />
      <AboutUs ref={aboutUs.ref} />
      <Contact ref={contact.ref} />
    </>
  );
};

export default Home;
