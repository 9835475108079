//  libraries
import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100%;
  width: 40%;

  @media screen and (max-width: 650px) {
    width: 80%;
    height: 50vh;
  }
`;

export const Embed = styled.iframe`
  flex: 1;
  height: 100%;
  width: 100%;
  border-radius: 5px;
`;
