//  hooks
import { forwardRef } from "react";

//  components
import { FormattedMessage } from "react-intl";

//  assets
import { spaces1Img, spaces2Img } from "../../assets/photos/spaces";

//  styles
import { Title } from "../common/common";
import { Wrapper, Content, Img } from "./styles";

const Spaces = forwardRef((props, ref) => {
  return (
    <Wrapper ref={ref}>
      <Title>
        <FormattedMessage id="spaces.heading" />
      </Title>
      <Content>
        <Img src={spaces1Img} loading="lazy" />
        <Img src={spaces2Img} loading="lazy" />
      </Content>
    </Wrapper>
  );
});

export default Spaces;
