//  libraries
import styled from "styled-components";

//  assets
import { typographies } from "../common/typographies";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  background-color: whitesmoke;
  padding: 1rem;
  border-radius: 4px;
  width: 25%;

  @media screen and (max-width: 650px) {
    width: 80%;
  }
`;

export const Question = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  width: 100%;
`;

export const Label = styled.div`
  font-weight: bold;
  color: rgb(80, 80, 80);
`;

export const Input = styled.input`
  font-size: 1rem;
  color: whitesmoke;
  background-color: rgb(80, 80, 80);
  width: 100%;
  padding: 0.3rem 0.5rem;
  border: 2px solid transparent;
  border-radius: 3px;
  box-sizing: border-box;

  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:hover {
    cursor: pointer;
    background-color: rgb(100, 100, 100);
  }

  &:focus {
    cursor: text;
  }
`;

export const Select = styled.select`
  font-size: 1rem;
  color: whitesmoke;
  background-color: rgb(80, 80, 80);
  width: 100%;
  padding: 0.3rem 0.5rem;
  border: 2px solid transparent;
  border-radius: 3px;
  box-sizing: border-box;

  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:hover {
    cursor: pointer;
    background-color: rgb(100, 100, 100);
  }
`;

export const FieldAlert = styled.div`
  text-align: end;
  color: rgb(200, 0, 0);
  font-size: 0.7rem;
  font-weight: bold;
  width: 100%;
  height: 0.7rem;
`;

export const SubmitButton = styled.button`
  @font-face {
    font-family: "bodyTypography";
    src: url(${typographies.bodyTypography});
  }

  font-family: "bodyTypography";
  display: flex;
  justify-content: center;
  align-self: flex-end;
  font-size: 0.8rem;
  width: fit-content;
  margin-top: 10px;
  padding: 1rem;
  font-weight: bold;
  border-style: none;
  border-radius: 5px;
  background-color: rgb(30, 30, 30);
  color: whitesmoke;
  box-shadow: 3px 3px 5px rgb(50, 50, 50);

  &:hover {
    background-color: rgb(80, 80, 80);
    cursor: pointer;
  }
`;
