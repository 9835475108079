//  styles
import { Wrapper, Embed } from "./styles";

const Map = () => {
  return (
    <Wrapper>
      <Embed
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3487.0811334593873!2d-110.98311618542813!3d29.07374738224581!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86ce8573e34e0aa5%3A0x453c539fb508f1c3!2sH1-Coworking!5e0!3m2!1ses-419!2smx!4v1676418896331!5m2!1ses-419!2smx"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></Embed>
    </Wrapper>
  );
};
export default Map;
