//  components
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

//  styles
import { MenuAlt, MenuItemAlt, linkStyle } from "./styles";

const ServicesMenuAlt = () => {
  return (
    <MenuAlt>
      <Link to={"/service/private-offices"} style={linkStyle}>
        <MenuItemAlt>
          <FormattedMessage id="header.nav.services.privateOffices" />
        </MenuItemAlt>
      </Link>
      <Link to={"/service/boardroom"} style={linkStyle}>
        <MenuItemAlt>
          <FormattedMessage id="header.nav.services.boardroom" />
        </MenuItemAlt>
      </Link>
      <Link to={"/service/coworking"} style={linkStyle}>
        <MenuItemAlt>
          <FormattedMessage id="header.nav.services.coworking" />
        </MenuItemAlt>
      </Link>
      <Link to={"/service/virtual-office"} style={linkStyle}>
        <MenuItemAlt>
          <FormattedMessage id="header.nav.services.virtualOffice" />
        </MenuItemAlt>
      </Link>
    </MenuAlt>
  );
};

export default ServicesMenuAlt;
