//  components
import { FormattedMessage } from "react-intl";

//  styles
import {
  Wrapper,
  Description,
  Paragraph,
  Element,
  Subelement,
  Image,
} from "./styles";

const ServiceDescription = ({ description, img }) => {
  return (
    <Wrapper>
      {description && (
        <FormattedMessage
          id={description}
          values={{
            Description: (string) => <Description>{string}</Description>,
            Paragraph: (string) => <Paragraph>{string}</Paragraph>,
            Element: (string) => <Element>{string}</Element>,
            Subelement: (string) => <Subelement>{string}</Subelement>,
          }}
        />
      )}

      <Image src={img} loading="lazy" />
    </Wrapper>
  );
};

export default ServiceDescription;
