//  hooks
import { forwardRef } from "react";

//  components
import ContactForm from "../ContactForm/ContactForm";
import Map from "../Map/Map";
import { FormattedMessage } from "react-intl";

//  styles
import { Title } from "../common/common";
import { Wrapper, Content } from "./styles";

const Contact = forwardRef((props, ref) => {
  return (
    <Wrapper ref={ref}>
      <Title>
        <FormattedMessage id="contact.heading" />
      </Title>
      <Content>
        <Map />
        <ContactForm />
      </Content>
    </Wrapper>
  );
});

export default Contact;
