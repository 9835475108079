//  hooks
import { forwardRef } from "react";
import { useOnScreen } from "use-hooks";

//  components
import { FormattedMessage } from "react-intl";

//  assets
import { backgroundAboutUs } from "../../assets/photos/aboutUs";

//  styles
// import { typographies } from "../common/typographies";

import { Title } from "../common/common";
import {
  Wrapper,
  BackgroundImg,
  Overlay,
  SectionWrapper,
  Header,
  Paragraph,
  Values,
  Value,
  Principles,
  Principle,
} from "./styles";

const AboutUs = forwardRef((props, ref) => {
  const onScreen = useOnScreen(ref);

  return (
    <Wrapper ref={ref}>
      <BackgroundImg
        src={backgroundAboutUs}
        opacityLevel={onScreen ? 1 : 0}
        loading="lazy"
      />
      <Overlay>
        <Title>
          <FormattedMessage id="aboutUs.heading" />
        </Title>
        <FormattedMessage
          id="aboutUs.body"
          values={{
            SectionWrapper: (string) => (
              <SectionWrapper>{string}</SectionWrapper>
            ),
            Header: (string) => <Header>{string}</Header>,
            Paragraph: (string) => <Paragraph>{string}</Paragraph>,
            Values: (string) => <Values>{string}</Values>,
            Value: (string) => <Value>{string}</Value>,
            Principles: (string) => <Principles>{string}</Principles>,
            Principle: (string) => <Principle>{string}</Principle>,
          }}
        />
      </Overlay>
    </Wrapper>
  );
});

export default AboutUs;
