import { createRef, useEffect } from "react";
import { useOnScreen } from "use-hooks";
import { useWindowSize } from "use-hooks";

export const useSelectRef = (name, setActive, clicked, setClicked) => {
  const ref = createRef();
  const windowSize = useWindowSize();

  const onScreenDesktop = useOnScreen(ref, "-300px");
  const onScreenMobile = useOnScreen(ref, "-150px");
  const onScreen = windowSize.width < "400" ? onScreenMobile : onScreenDesktop;

  useEffect(() => {
    if (onScreen && !clicked) setActive(name);
  }, [onScreen, clicked]);

  const scroll = () => {
    setActive(name);
    setClicked(true);
    setTimeout(() => {
      setClicked(false);
    }, 1500);
    const section = ref.current.offsetTop - 80;
    window.scrollTo({
      top: section,
      behavior: "smooth",
    });
  };

  const section = {
    ref,
    scroll,
    onScreen,
  };

  return section;
};
