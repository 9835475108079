//  libraries
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  height: fit-content;
  overflow: hidden;
  padding-bottom: 2rem;
  z-index: 1;
  background-color: rgb(30, 30, 30);
`;

export const Content = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  column-gap: 1rem;
  max-width: 80vw;

  @media screen and (max-width: 970px) {
    flex-direction: column;
    row-gap: 1rem;
  }
`;

export const Img = styled.img`
  max-width: 80vw;
`;
