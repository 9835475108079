//  hooks
import { useNavBar } from "../../hooks/useNavBar";
import { useNavigate } from "react-router-dom";

//  components
import { FormattedMessage } from "react-intl";

//  styles
import {
  SlideWrapper,
  Photo,
  Overlay,
  Content,
  Copy,
  Description,
  ButtonSection,
  CTAButton,
} from "./styles";

const Slide = ({ slide }) => {
  const { contact } = useNavBar();
  const navigate = useNavigate();

  return (
    <SlideWrapper>
      <Photo src={slide.img} />
      <Overlay>
        <Content>
          <Copy>
            <FormattedMessage id={slide.service} />
          </Copy>
          <Description>
            <FormattedMessage
              id={slide.description}
              values={{ li: (string) => <li>{string}</li> }}
            />
          </Description>
        </Content>
        <ButtonSection>
          {slide.path && (
            <CTAButton onClick={() => navigate(`/service/${slide.path}`)}>
              Ver más
            </CTAButton>
          )}
          <CTAButton onClick={contact.scroll}>Contáctanos</CTAButton>
        </ButtonSection>
      </Overlay>
    </SlideWrapper>
  );
};

export default Slide;
