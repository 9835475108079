//  libraries
import styled from "styled-components";

//  assets
// import { typographies } from "../common/typographies";

export const Wrapper = styled.div`
  margin-top: 5rem;
  height: 50vh;
  width: 100vw;
`;

export const Overlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  padding: 0 10vw;
  width: 100vw;
  height: 50vh;
  background-image: linear-gradient(
    to right,
    rgba(30, 30, 30, 0.9) 20%,
    rgba(30, 30, 30, 0.4)
  );
  z-index: 2;

  @media screen and (max-width: 650px) {
    padding: 0;
  }
`;

export const BackgroundImg = styled.img`
  position: absolute;
  width: 100vw;
  height: 50vh;
  object-fit: cover;
  z-index: 1;
`;

export const Title = styled.div`
  @font-face {
    font-family: "HeaderTypography";
    src: url(typographies.headerTypography);
  }

  font-family: "HeaderTypography";
  color: whitesmoke;
  text-align: center;
  font-size: 4rem;
  font-weight: bold;
  /* max-width: 40vw; */
  text-shadow: 5px 5px 5px rgb(50, 50, 50);

  @media screen and (max-width: 650px) {
    font-size: 3rem;
  }
`;
