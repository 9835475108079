//  libraries
import { send } from "emailjs-com";

//  hooks
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

//  components
import { FormattedMessage } from "react-intl";

//  validations
import { contactFormSchema } from "../../validations/contactFormValidation";

//  styles
// import { typographies } from "../common/typographies";
import {
  Form,
  Question,
  Input,
  Label,
  FieldAlert,
  Select,
  SubmitButton,
} from "./styles";

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(contactFormSchema),
  });

  const onSubmit = (data) => {
    const {
      REACT_APP_EMAILJS_SERIVCE_ID,
      REACT_APP_EMAILJS_TEMPLATE_ID,
      REACT_APP_EMAILJS_PUBLIC_KEY,
    } = process.env;
    send(
      REACT_APP_EMAILJS_SERIVCE_ID,
      REACT_APP_EMAILJS_TEMPLATE_ID,
      data,
      REACT_APP_EMAILJS_PUBLIC_KEY
    )
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        alert("Se ha enviado su información exitosamente");
      })
      .catch((err) => {
        console.log("FAILED...", err);
        alert(
          "Envío de formulario fallido. Por favor vuelve a intentarlo o comunícate con nosotros a coworking.h1@gmail.com"
        );
      });
    reset();
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Question>
        <Label htmlFor="name">
          <FormattedMessage id="contact.form.name" />
        </Label>
        <Input id="name" {...register("name", { required: true })} />
        <FieldAlert>{errors?.name?.message}</FieldAlert>
      </Question>
      <Question>
        <Label htmlFor="email">
          <FormattedMessage id="contact.form.email" />
        </Label>
        <Input id="email" {...register("email", { required: true })} />
        <FieldAlert>{errors?.email?.message}</FieldAlert>
      </Question>
      <Question>
        <Label htmlFor="phone">
          <FormattedMessage id="contact.form.phone" />
        </Label>
        <Input id="phone" {...register("phone", { required: true })} />
        <FieldAlert>{errors?.phone?.message}</FieldAlert>
      </Question>
      <Question>
        <Label htmlFor="service">
          <FormattedMessage id="contact.form.service" />
        </Label>
        <Select
          id="service"
          defaultValue={"default"}
          {...register("service", { required: true })}
        >
          <option value="default" disabled hidden>
            <FormattedMessage id="contact.form.service.default" />
          </option>
          <option value="Oficinas Privadas">
            <FormattedMessage id="contact.form.service.privateOffices" />
          </option>
          <option value="Sala de juntas">
            <FormattedMessage id="contact.form.service.boardroom" />
          </option>
          <option value="Coworking">
            <FormattedMessage id="contact.form.service.coworking" />
          </option>
        </Select>
        <FieldAlert>{errors?.service?.message}</FieldAlert>
      </Question>

      <SubmitButton>
        <FormattedMessage id="contact.form.button" />
      </SubmitButton>
    </Form>
  );
};

export default ContactForm;
