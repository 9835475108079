//  hooks
import { useLanguage } from "../../hooks/useLanguage";
import { useWindowSize } from "use-hooks";

//  assets
import LanguageIcon from "@mui/icons-material/Language";

//  styles
import { Wrapper, Select, SelectMobile } from "./styles";

const LanguageSelector = () => {
  const { selectLanguage } = useLanguage();
  const windowSize = useWindowSize();

  return (
    <Wrapper>
      {windowSize.width > 650 ? (
        <>
          <LanguageIcon />
          <Select
            defaultValue={localStorage.getItem("language") || "es-MX"}
            onChange={(e) => selectLanguage(e)}
          >
            <option value="en-US">English</option>
            <option value="es-MX">Español</option>
          </Select>
        </>
      ) : (
        <SelectMobile
          defaultValue={localStorage.getItem("language") || "es-MX"}
          onChange={(e) => selectLanguage(e)}
        >
          <option value="en-US">en</option>
          <option value="es-MX">es</option>
        </SelectMobile>
      )}
    </Wrapper>
  );
};

export default LanguageSelector;
