//  libraries
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: fit-content;
  overflow: hidden;
`;

export const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  row-gap: 1.5rem;
  position: relative;
  padding: 1rem 15vw 2rem 15vw;
  width: 100vw;
  height: 102%;
  background-color: rgba(30, 30, 30, 0.8);
  z-index: 2;
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: fit-content;
  row-gap: 1rem;
`;

export const BackgroundImg = styled.img`
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  overflow: hidden;
  opacity: ${({ opacityLevel }) => opacityLevel};
  transition: opacity 100ms linear;
`;

export const Header = styled.h1`
  @font-face {
    font-family: "HeaderTypography";
    src: url(typographies.headerTypography);
  }

  font-family: "HeaderTypography";
  font-size: 2rem;
  font-weight: bold;
`;

export const Values = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
`;

export const Value = styled.li`
  @font-face {
    font-family: "HeaderTypography";
    src: url(typographies.headerTypography);
  }

  font-family: "HeaderTypography";
  font-size: 1.4rem;
  font-weight: bold;
  margin-left: 2vw;
`;

export const Principles = styled.ul`
  display: flex;
  flex-direction: column;
  margin-left: 3vw;
  list-style-type: circle;
`;

export const Principle = styled.li`
  font-size: 1.2rem;
  line-height: 1.6rem;
  width: 100%;
`;

export const Paragraph = styled.div`
  width: 100%;
`;
