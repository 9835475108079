//  hooks
import { useNavigate } from "react-router-dom";

//  assets
import { logoImg, isotypeImg } from "../../assets/logos";

//  styles
import { Wrapper, Img } from "./styles";

const Logo = ({ type, size }) => {
  const navigate = useNavigate();
  return (
    <Wrapper size={size} onClick={() => navigate("/")}>
      <Img
        size={size}
        src={type === "isotype" ? isotypeImg : logoImg}
        alt="logo"
      />
    </Wrapper>
  );
};
export default Logo;
