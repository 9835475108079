//  libraries
import styled from "styled-components";

// styles
const Wrapper = styled.div`
  height: 100vh;
  padding: 5rem;
  margin-top: 5rem;
`;

const NotFound = () => {
  return (
    <Wrapper>
      <h1>Error 404 Not Found</h1>
    </Wrapper>
  );
};

export default NotFound;
