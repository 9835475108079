//  libraries
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//  components
import Slider from "react-slick";
import Slide from "./Slide";

//  assets
import {
  kitchen,
  boardroom,
  office,
  coworking,
  virtualOffice,
} from "../../assets/photos/services";

//  styles
import { CarouselWrapper } from "./styles";

const slides = [
  {
    service: "services.slider.privateOffices.title",
    description: "services.slider.privateOffices.points",
    img: office,
    path: "private-offices",
  },
  {
    service: "services.slider.boardroom.title",
    description: "services.slider.boardroom.points",
    img: boardroom,
    path: "boardroom",
  },
  {
    service: "services.slider.coworking.title",
    description: "services.slider.coworking.points",
    img: coworking,
    path: "coworking",
  },
  {
    service: "services.slider.vitualOffice.title",
    description: "services.slider.vitualOffice.points",
    img: virtualOffice,
    path: "virtual-office",
  },
  {
    service: "services.slider.commonAreas.title",
    description: "services.slider.commonAreas.points",
    img: kitchen,
  },
];

const ServicesCarousel = () => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    lazyLoad: true,
  };

  return (
    <CarouselWrapper>
      <Slider {...settings}>
        {slides.map((slide, i) => {
          return <Slide slide={slide} key={i} />;
        })}
      </Slider>
    </CarouselWrapper>
  );
};

export default ServicesCarousel;
