// import axios from "axios";

//  hooks
// import { useState } from "react";
import { forwardRef } from "react";

//  components
// import Lightbox from "../Lightbox/Lightbox";
import InstagramFeed from "../InstagramFeed/InstagramFeed";
import { FormattedMessage } from "react-intl";

//  assets
// import { backgroundAboutUs } from "../../assets/photos/aboutUs";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

//  styles
import { Title, SocialMedia } from "../common/common";
import { Wrapper } from "./styles";

const Community = forwardRef((props, ref) => {
  const limit = 9;
  const { REACT_APP_INSTAGRAM_TOKEN } = process.env;
  const token = REACT_APP_INSTAGRAM_TOKEN;

  return (
    <Wrapper ref={ref}>
      <Title>
        <FormattedMessage id="community.heading" />
      </Title>
      <InstagramFeed token={token} limit={limit} />

      <SocialMedia>
        <FacebookIcon
          onClick={() => window.open("https://www.facebook.com/H1.coworking/")}
        />
        <InstagramIcon
          onClick={() => window.open("https://www.instagram.com/h1.coworking/")}
        />
        h1.coworking
      </SocialMedia>
    </Wrapper>
  );
});

export default Community;
