//  components
import { FormattedMessage } from "react-intl";

//  styles
import { Wrapper, BackgroundImg, Overlay, Title } from "./styles";

const ServiceHero = ({ title, banner }) => {
  return (
    <Wrapper>
      {banner && <BackgroundImg src={banner} loading="lazy" />}
      <Overlay>
        <Title>{title && <FormattedMessage id={title} />}</Title>
      </Overlay>
    </Wrapper>
  );
};

export default ServiceHero;
