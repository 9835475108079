//  libraries
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  column-gap: 3rem;
  width: 80%;
  height: fit-content;
  padding: 1rem;

  @media screen and (max-width: 650px) {
    flex-direction: column;
    margin: 2rem 0;
  }
`;

export const Paragraph = styled.p`
  font-size: 1.4rem;
  line-height: 1.5rem;
  margin: 1rem;
`;

export const Element = styled.li`
  line-height: 1.5rem;
  margin: 1rem;
  list-style-type: disc;
`;

export const Subelement = styled.li`
  line-height: 1.5rem;
  font-size: 1rem;
  margin: 0.8rem 2rem;
  list-style-type: circle;
`;

export const Description = styled.ul`
  font-size: 1.2rem;
`;

export const Image = styled.img`
  width: 30vw;
  height: 30vw;
  border-radius: 50%;
  object-fit: cover;

  @media screen and (max-width: 650px) {
    width: 70vw;
    height: 70vw;
  }
`;
