//  hooks
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

//  components
import ServiceHero from "../components/ServiceHero/ServiceHero";
import ServiceDescription from "../components/ServiceDescription/ServiceDescription";
import ServiceNav from "../components/ServiceNav/ServiceNav";
import Contact from "../components/Contact/Contact";

//  assets
import {
  bannerBoardroom,
  descriptionBoardroom,
} from "../assets/photos/boardroom";
import {
  bannerCoworking,
  descriptionCoworking,
} from "../assets/photos/coworking";
import { bannerOffices, descriptionOffices } from "../assets/photos/offices";
import {
  bannerVirtualOffice,
  descriptionVirtualOffice,
} from "../assets/photos/virtualOffice";

const Service = () => {
  const location = useLocation();
  const path = location.pathname.split("/").slice(-1)[0];

  const [service, setService] = useState({});

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  });

  useEffect(() => {
    if (path === "private-offices") {
      setService({
        title: "privateOffices.heading",
        description: "privateOffices.description",
        banner: bannerOffices,
        descriptionImage: descriptionOffices,
      });
    }
    if (path === "boardroom") {
      setService({
        title: "boardroom.heading",
        description: "boardroom.description",
        banner: bannerBoardroom,
        descriptionImage: descriptionBoardroom,
      });
    }
    if (path === "coworking") {
      setService({
        title: "coworking.heading",
        description: "coworking.description",
        banner: bannerCoworking,
        descriptionImage: descriptionCoworking,
      });
    }
    if (path === "virtual-office") {
      setService({
        title: "virtualOffice.heading",
        description: "virtualOffice.description",
        banner: bannerVirtualOffice,
        descriptionImage: descriptionVirtualOffice,
      });
    }
  }, [path]);

  return (
    <>
      <ServiceHero title={service.title} banner={service.banner} />
      <ServiceDescription
        description={service.description}
        img={service.descriptionImage}
      />
      <ServiceNav setService={setService} />
      <Contact />
    </>
  );
};

export default Service;
