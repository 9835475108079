//  libraries
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  row-gap: 2rem;
  width: 100vw;
  height: fit-content;
  overflow: hidden;
  padding-bottom: 2rem;
  z-index: 9;
  background-color: rgb(30, 30, 30);
`;

export const Gallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 80vw;
  justify-content: center;
  align-items: flex-start;
  row-gap: 1rem;
  column-gap: 1rem;
  padding: 1rem;
`;
