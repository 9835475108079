//  libraries
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 0.1rem;
  background-color: whitesmoke;
  color: rgb(30, 30, 30);
  border-radius: 5px;
  padding: 0.3rem;
  opacity: 0.8;
`;

export const Select = styled.select`
  font-size: 0.8rem;
  color: rgb(30, 30, 30);
  background-color: whitesmoke;
  width: 100%;
  padding: 0.1rem;
  border: 2px solid transparent;
  border-radius: 3px;
  box-sizing: border-box;

  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:hover {
    cursor: pointer;
    background-color: rgb(100, 100, 100);
    color: whitesmoke;
  }
`;

export const SelectMobile = styled.select`
  font-size: 0.8rem;
  color: rgb(30, 30, 30);
  background-color: whitesmoke;
  width: 100%;
  padding: 0.1rem;
  border: 2px solid transparent;
  border-radius: 3px;
  box-sizing: border-box;

  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:hover {
    cursor: pointer;
    background-color: rgb(100, 100, 100);
    color: whitesmoke;
  }
`;
