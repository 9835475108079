//  libraries
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  row-gap: 1rem;
  width: 100vw;
  height: 90vh;
  padding: 1rem;
  overflow: hidden;
  background-color: rgb(30, 30, 30);
  z-index: 1;

  @media screen and (max-width: 650px) {
    height: fit-content;
    justify-content: center;
    align-items: center;
    padding-bottom: 2.5rem;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 4rem;
  width: 100%;

  @media screen and (max-width: 650px) {
    flex-direction: column-reverse;
    row-gap: 1.5rem;
    height: fit-content;
  }
`;
