//  libraries
import styled from "styled-components";

export const Bar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 5rem;
  padding: 0 1rem;
  color: whitesmoke;
  background-color: ${({ opacityLevel }) =>
    opacityLevel ? "rgba(20, 20, 20, 0.4)" : "rgb(20, 20, 20)"};
  transition: background-color 200ms linear, border-style 200ms linear;
  z-index: 10;
  border-bottom-style: ${({ opacityLevel }) =>
    opacityLevel ? "none" : "solid"};
  border-width: 1px;
`;

export const MobileWrapper = styled.div`
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
`;
