//  libraries
import styled from "styled-components";

export const LightboxBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 5rem;
  background-color: rgba(20, 20, 20, 0.8);
  width: 100vw;
  height: 90vh;
`;

export const LightboxImg = styled.img`
  width: 80%;
  height: 80%;
  object-fit: contain;
  pointer-events: none;
`;
