//  libraries
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 2rem;
  width: 100%;
  background-color: rgb(100, 100, 100);
  padding: 1rem;
`;
export const Title = styled.h1`
  color: whitesmoke;
  font-size: 2rem;
  font-weight: bold;
`;

export const Links = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5vw;
`;
