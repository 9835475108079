//  styles
import { PlaceholderImg, PlaceholderVideo } from "./styles";

const Feed = ({ feed, setLightboxImg }) => {
  const { id, caption, media_type, media_url } = feed;
  let post;

  switch (media_type) {
    case "VIDEO":
      post = (
        <PlaceholderVideo
          width="100%"
          height="auto"
          src={media_url}
          type="video/mp4"
          controls
          playsinline
          onClick={() => setLightboxImg(media_url)}
        ></PlaceholderVideo>
      );
      break;
    case "CAROUSEL_ALBUM":
      post = (
        <PlaceholderImg
          width="100%"
          height="auto"
          id={id}
          src={media_url}
          alt={caption}
          onClick={() => setLightboxImg(media_url)}
        />
      );
      break;
    default:
      post = (
        <PlaceholderImg
          width="100%"
          height="auto"
          id={id}
          src={media_url}
          alt={caption}
          onClick={() => setLightboxImg(media_url)}
        />
      );
  }

  return <>{post}</>;
};

export default Feed;
