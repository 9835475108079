//  libraries
import styled from "styled-components";

//  assets
import { typographies } from "../common/typographies";

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  justify-content: space-around;
  border-style: none solid;
  border-color: rgba(100, 100, 100, 0.9);
  border-width: 1px;
  padding: 0 10px;
  opacity: 0.8;
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  position: fixed;
  top: 5rem;
  right: 0;
  color: whitesmoke;
  /* background-color: ${({ opacityLevel }) =>
    opacityLevel ? "rgba(20, 20, 20, 0.4)" : "rgb(20, 20, 20)"}; */
  background-color: rgba(20, 20, 20, 0.4);
  transition: background-color 200ms linear, border-style 200ms linear;
  border-radius: 0 0 0 3px;
  padding: 0.5rem;
  z-index: 10;
`;

export const NavItem = styled.div`
  @font-face {
    font-family: "BodyTypography";
    src: url(${typographies.bodyTypography});
  }

  font-family: "BodyTypography";
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7rem;
  padding: 1rem;
  font-weight: bold;
  border-radius: 3px;
  margin: 0 4px;
  background-color: ${({ selected }) => selected && "whitesmoke"};
  color: ${({ selected }) => selected && "rgb(30, 30, 30)"};
  box-shadow: ${({ selected }) => selected && "3px 3px 5px rgb(50, 50, 50)"};

  &:hover {
    box-shadow: 3px 3px 5px rgb(50, 50, 50);
    color: rgb(30, 30, 30);
    background-color: whitesmoke;
    cursor: pointer;
  }
`;
