import axios from "axios";

//  hooks
import { useState, useEffect, useRef } from "react";
import { useWindowSize } from "use-hooks";

// components
import Feed from "./Feed";
import Lightbox from "../Lightbox/Lightbox";
import InstagramSlider from "./InstagramSlider";

//  styles
import { Gallery } from "./styles";

const InstagramFeed = ({ token, limit }) => {
  const [lightboxImg, setLightboxImg] = useState(null);
  const [feeds, setFeeds] = useState(null);
  const windowSize = useWindowSize();
  const tokenProp = useRef(token);
  tokenProp.current = token;
  useEffect(() => {
    const abortController = new AbortController();

    const fetchInstaPost = async () => {
      try {
        const response = await axios.get(
          `https://graph.instagram.com/me/media?fields=id,media_type,media_url,caption&limit=${limit}&access_token=${token}`
        );
        setFeeds(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchInstaPost();

    return () => {
      abortController.abort();
    };
  }, [limit]);

  return (
    <>
      {windowSize.width > 400 ? (
        <Gallery>
          {feeds &&
            feeds.map((feed) => (
              <Feed key={feed.id} feed={feed} setLightboxImg={setLightboxImg} />
            ))}
        </Gallery>
      ) : (
        <>
          {feeds && (
            <InstagramSlider feeds={feeds} setLightboxImg={setLightboxImg} />
          )}
        </>
      )}
      {lightboxImg && <Lightbox img={lightboxImg} setImg={setLightboxImg} />}
    </>
  );
};

export default InstagramFeed;
