//  components
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

//  styles
import { linkStyle } from "../common/common";
import { Wrapper, Title, Links } from "./styles";

const ServiceNav = () => {
  return (
    <Wrapper>
      <Title>
        <FormattedMessage id="service.nav.heading" />
      </Title>
      <Links>
        <Link to={"/"} style={linkStyle}>
          <FormattedMessage id="service.nav.home" />
        </Link>
        <Link to={"/service/private-offices"} style={linkStyle}>
          <FormattedMessage id="service.nav.privateOffices" />
        </Link>
        <Link to={"/service/boardroom"} style={linkStyle}>
          <FormattedMessage id="service.nav.boardroom" />
        </Link>
        <Link to={"/service/coworking"} style={linkStyle}>
          <FormattedMessage id="service.nav.coworking" />
        </Link>
        <Link to={"/service/virtual-office"} style={linkStyle}>
          <FormattedMessage id="service.nav.virtualOffice" />
        </Link>
      </Links>
    </Wrapper>
  );
};

export default ServiceNav;
