//  libraries
import styled from "styled-components";

export const Gallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 80vw;
  justify-content: center;
  align-items: flex-start;
  row-gap: 1rem;
  column-gap: 1rem;
  padding: 1rem;
`;

export const PlaceholderImg = styled.img`
  width: 30%;
  height: 30%;
  border-radius: 2px;
  object-fit: cover;
  cursor: pointer;

  @media screen and (max-width: 650px) {
    width: 50%;
    height: 50%;
  }

  @media screen and (max-width: 400px) {
    width: 100%;
    height: 100%;
  }
`;

export const PlaceholderVideo = styled.video`
  width: 30%;
  height: 30%;
  border-radius: 2px;
  object-fit: cover;
  cursor: pointer;

  @media screen and (max-width: 650px) {
    width: 50%;
    height: 50%;
  }

  @media screen and (max-width: 400px) {
    width: 100%;
    height: 100%;
  }
`;

export const CarouselWrapper = styled.div`
  height: 70vh;
  width: 100vw;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 80%,
    rgba(255, 255, 255, 0)
  );
  color: whitesmoke;
  margin-bottom: 1rem;
  border-radius: 3px;
  overflow: hidden;
`;

export const SlideWrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 65vh;
  z-index: 0;
  overflow: hidden;
`;
