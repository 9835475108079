//  hooks
import { useState, createContext } from "react";
import { useSelectRef } from "../hooks/useSelectRef";

const NavigationContext = createContext({});

export const NavigationProvider = ({ children }) => {
  const [active, setActive] = useState("hero");
  const [clicked, setClicked] = useState(false);

  const hero = useSelectRef("hero", setActive, clicked, setClicked);
  const services = useSelectRef("services", setActive, clicked, setClicked);
  const spaces = useSelectRef("spaces", setActive, clicked, setClicked);
  const community = useSelectRef("community", setActive, clicked, setClicked);
  const aboutUs = useSelectRef("aboutUs", setActive, clicked, setClicked);
  const contact = useSelectRef("contact", setActive, clicked, setClicked);

  return (
    <NavigationContext.Provider
      value={{
        hero,
        services,
        spaces,
        community,
        aboutUs,
        contact,
        active,
        setActive,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export default NavigationContext;
